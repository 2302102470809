<template>
  <section class="field-styling-drawer">
    <el-scrollbar class="scrollable-list">
      <div v-if="data && data.styles" class="field-styling-component">
        <div class="d-flex">
          <strong class="mr-1">Field</strong>
          <span>
            <span>{{ data.label }}</span>
            - {{ data.input_type }}
          </span>
        </div>
        <div class="d-flex">
          <span>Appearance</span>
          <span>
            <el-select v-model="data.properties.filed_content">
              <el-option
                v-for="(property, index) of propertiesList"
                :value="property.value"
                :label="property.name"
                :key="index"
                :disabled="
                  data.input_type != 'ENTITY_VARIABLE' &&
                  data.data_table_field_index == -1
                    ? data.validations && data.validations.required
                    : false
                "
              >
                <span style="float: left">{{ property.name }}</span>
                <!-- <span style="float: right; color: #8492a6; font-size: 13px" v-html="border.demo"></span> -->
              </el-option>
            </el-select>
          </span>
        </div>
        <div v-if="field.inputType == 'DATA_TABLE'">
          <span>Select View Type</span>
          <el-select
            v-model="field.table_data_view_type"
            placeholder="Select View Type"
          >
            <el-option
              v-for="item in viewOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span
            class="mt-1"
            v-if="field.table_data_view_type == 'Accordion_view'"
            >Coose Header From</span
          >
          <el-select
            v-if="field.table_data_view_type == 'Accordion_view'"
            v-model="field.selected_header_title"
            placeholder="Select header ttile"
          >
            <el-option
              v-for="(column, index) in field.data_table_columns"
              :key="index"
              :label="column.label"
              :value="column.label"
            />
          </el-select>
          <el-checkbox
            v-if="field.table_data_view_type == 'table_view'"
            label="Allow data adding through Excel"
            v-model="field.add_through_excel"
          >
          </el-checkbox>
          <el-checkbox
            v-if="
              !field.card_view && field.table_data_view_type == 'Accordion_view'
            "
            label="Hide Add & Remove Buttons"
            v-model="field.hide_add_remove_buttons"
          ></el-checkbox>
        </div>
        <div v-if="data.input_type == 'DATA_TABLE'" class="check-box-style">
          <el-checkbox v-model="data.properties.enable_add_button"
            >Enable add row button</el-checkbox
          >
        </div>
        <div v-if="data.input_type == 'DATA_TABLE'" class="check-box-style">
          <el-checkbox v-model="data.properties.enable_plus_button"
            >Enable + button</el-checkbox
          >
        </div>
        <div v-if="data.input_type == 'DATA_TABLE'" class="check-box-style">
          <el-checkbox v-model="data.properties.enable_minus_button"
            >Enable - button</el-checkbox
          >
        </div>
        <div v-if="data.input_type == 'DATA_TABLE'" class="d-flex">
          <span>Open Default number of rows from</span>
          <span style="margin-left: 5px">
            <el-select v-model="data.properties.take_number_from" collapse-tags>
              <el-option
                v-for="(option, index) of getSegregatedNumberFields"
                :value="option.key"
                :label="option.label"
                :key="index"
              ></el-option>
            </el-select>
          </span>
        </div>
        <div v-if="data.properties.enable_add_button">
          <span>Specify button label</span>
          <el-input
            v-model="data.properties.add_button_name"
            :placeholder="'Specify add row button name'"
          ></el-input>
        </div>
        <!-- <template v-if="!data.is_global_variable">
          <div class="d-flex">
            <span>
            <el-switch v-model="data.properties.qr_code_scanning" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
          </span>
          <span>Enable QR  Code Scanning?</span>
          </div>
          
        </template> -->

        <template
          v-if="
            allowingFieldTypes.includes(data.input_type)

            // data.input_type === 'IMAGE' ||
            // data.input_type === 'HTML_CONTENT' ||
            // data.input_type === 'QUESTION' ||
            // data.input_type === 'HEADING' ||
            // data.input_type === 'PARAGRAPH' ||
            // data.input_type === 'SINGLE_LINE_CONTENT' ||
            // data.input_type === 'VIDEO' ||
            // data.input_type === 'HORIZONTAL_LINE' ||
            // data.input_type === 'DIVISION' ||
          "
        >
          <div class="d-flex" v-if="data.input_type !== 'CHECKBOX_GROUP'">
            <span>
              <el-switch v-model="data.properties.hideLabel"></el-switch>
            </span>
            <span>hide Label</span>
          </div>
          <div
            class="d-flex"
            v-if="
              !['CHECKBOX_GROUP', 'HTML_CONTENT', 'CALENDER'].includes(
                data.input_type
              )
            "
          >
            <span>
              <el-switch v-model="data.properties.prefix"></el-switch>
            </span>
            <span>Add Icon to filed </span>
          </div>
          <div
            class="d-flex"
            v-if="
              data.properties.prefix && data.input_type !== 'CHECKBOX_GROUP'
            "
          >
            <span>Choose Icon </span>
            <span>
              <el-input
                v-model="data.properties.el_icon"
                placeholder="Add EL-Icon Code"
                clearable
                size="medium"
              >
                <el-button
                  type="plain"
                  @click="addiconsToFromTemplates()"
                  slot="append"
                  icon="el-icon-plus"
                ></el-button>
              </el-input>
            </span>
          </div>

          <div
            class="d-flex"
            v-if="
              !data.is_global_variable &&
              data.input_type !== 'CHECKBOX_GROUP' &&
              data.input_type !== 'HTML_CONTENT' &&
              data.input_type !== 'CALENDER'
            "
          >
            <span>
              <el-switch v-model="data.properties.is_unique"></el-switch>
            </span>
            <span>Do Not Allow Duplicates</span>
          </div>
          <div
            class="d-flex"
            v-if="
              !data.is_global_variable &&
              data.input_type !== 'CHECKBOX_GROUP' &&
              data.input_type !== 'HTML_CONTENT' &&
              data.input_type !== 'CALENDER'
            "
          >
            <span>
              <el-switch
                v-model="data.properties.qr_code_scanning"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </span>
            <span>Enable QR Code Scanning?</span>
          </div>

          <div
            class="d-flex"
            v-if="
              data.input_type !== 'CHECKBOX_GROUP' &&
              data.input_type !== 'HTML_CONTENT' &&
              data.input_type !== 'CALENDER'
            "
          >
            <span>
              <el-switch v-model="data.properties.personal_data"></el-switch>
            </span>
            <span>Contains Personal Data</span>
          </div>

          <div class="d-flex">
            <el-radio-group
              v-model="data.properties.personal_data_type"
              v-if="
                data.properties.personal_data &&
                data.input_type !== 'CHECKBOX_GROUP' &&
                data.input_type !== 'HTML_CONTENT' &&
                data.input_type !== 'CALENDER'
              "
            >
              <el-radio label="Normal"></el-radio>
              <el-radio label="Confidential"></el-radio>
            </el-radio-group>
          </div>

          <div
            class="d-flex"
            v-if="
              field.input_type !== 'HTML_CONTENT' &&
              data.input_type !== 'CALENDER'
            "
          >
            <span>
              <el-switch v-model="data.properties.is_encrypted"></el-switch>
            </span>
            <span>Encrypt Field</span>
          </div>
          <template
            v-if="!data.is_global_variable && data.input_type === 'DATE'"
          >
            <div>
              <el-checkbox
                v-model="data.properties.autofill_response"
                :disabled="data.properties.defaultTodayDate"
                >Auto Fill Current Date</el-checkbox
              >
            </div>
          </template>
          <template
            v-if="!data.is_global_variable && data.input_type === 'TIME'"
          >
            <div>
              <el-checkbox
                v-model="data.properties.autofill_time_response"
                :disabled="data.properties.defaultTodayTime"
                >Auto Fill Current Time</el-checkbox
              >
            </div>
          </template>
          <template
            v-if="!data.is_global_variable && data.input_type === 'DATE_TIME'"
          >
            <div>
              <el-checkbox
                v-model="data.properties.autofill_datetime_response"
                :disabled="data.properties.defaultTodayDateTime"
                >Auto Fill Current Date Time</el-checkbox
              >
            </div>
          </template>
          <template>
            <div class="test">
              <span
                v-if="
                  data.input_type !== 'CHECKBOX_GROUP' &&
                  data.input_type !== 'MULTI_SELECT' &&
                  data.input_type !== 'HTML_CONTENT' &&
                  data.input_type !== 'CALENDER'
                "
              >
                Take default value from:
              </span>
              <el-select
                placeholder="Default value"
                v-model="data.default_mapped_field"
                v-if="
                  data.input_type !== 'CHECKBOX_GROUP' &&
                  data.input_type !== 'MULTI_SELECT' &&
                  data.input_type !== 'HTML_CONTENT' &&
                  data.input_type !== 'CALENDER'
                "
                clearable
              >
                <el-option
                  v-for="(option, index) of getSameInputFields"
                  :value="option.key"
                  :label="option.label"
                  :key="index"
                ></el-option>
              </el-select>
              <br />
              <span
                v-if="data.input_type == 'NUMBER' && !data.default_mapped_field"
              >
                Take value from date:
              </span>
              <el-select
                placeholder="Map with date"
                v-model="data.default_mapped_date_field"
                v-if="data.input_type == 'NUMBER' && !data.default_mapped_field"
                clearable
              >
                <el-option
                  v-for="(option, index) of getDateFields"
                  :value="option.key"
                  :label="option.label"
                  :key="index"
                ></el-option>
              </el-select>
              <br />
              <span
                v-if="
                  data.input_type == 'NUMBER' &&
                  !data.default_mapped_field &&
                  data.default_mapped_date_field
                "
              >
                Value from date
              </span>
              <el-select
                placeholder="Value from date"
                v-model="data.default_mapped_date_type"
                v-if="
                  data.input_type == 'NUMBER' &&
                  !data.default_mapped_field &&
                  data.default_mapped_date_field
                "
                clearable
              >
                <el-option
                  v-for="(option, index) of dateValueOptions"
                  :value="option.key"
                  :label="option.name"
                  :key="index + '_' + option.key"
                ></el-option>
              </el-select>
              <el-input
                v-if="data.default_mapped_date_type == 'CR_CUSTOM_DAYS'"
                type="number"
                placeholder="Custom range in month"
                v-model="data.default_mapped_date_custom_range"
              ></el-input>
              <br />
              <el-checkbox
                v-model="data.table_first_row"
                v-if="data.default_mapped_field && isTableField"
                :disabled="data.table_last_row"
                >First row</el-checkbox
              >
              <el-checkbox
                v-model="data.table_last_row"
                v-if="data.default_mapped_field && isTableField"
                :disabled="data.table_first_row"
                >Last row</el-checkbox
              >
              <el-checkbox
                v-model="data.add_default_value"
                v-if="
                  data.default_mapped_field &&
                  (data.inputType == 'NUMBER' || data.inputType == 'CURRENCY')
                "
              >
                Add mapping value
              </el-checkbox>
              <el-checkbox
                v-model="data.replace_value"
                v-if="data.default_mapped_field"
              >
                Replace value
              </el-checkbox>
            </div>
          </template>
          <template v-if="!data.default_mapped_field">
            <div
              class="test"
              v-if="!data.is_global_variable && data.input_type !== 'SIGNATURE'"
            >
              <span
                v-if="
                  data.input_type !== 'DATE_TIME_RANGE' &&
                  data.input_type !== 'SELECT' &&
                  data.input_type !== 'MULTI_SELECT' &&
                  data.input_type !== 'LIST' &&
                  data.input_type !== 'HTML_CONTENT' &&
                  data.input_type !== 'CALENDER'
                "
              >
                Default Value:</span
              >
              <span
                :style="
                  hasError
                    ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                    : ''
                "
              >
                <template
                  v-if="data.input_type == 'ENTITY' && !data?.show_count"
                >
                  <el-select
                    v-model="data.default_value"
                    :placeholder="data.placeholder || data.label"
                    clearable
                    collapse-tags
                    :value-key="'entity_data_id'"
                    filterable
                    :suffix-icon="data.properties.el_icon"
                    :loading="optionsLoading"
                    :remote-method="searchEntityData"
                    remote
                    @clear="handleClear"
                    loading-text="Loading..."
                    :multiple="data.allow_multiple || false"
                    class="centered-text-input"
                  >
                    <el-option
                      v-for="(temp, i) in entitiesData"
                      :key="i"
                      :value="temp._id"
                      :label="getLabel(temp, i, currentEntity)"
                    ></el-option>
                    <el-option
                      value="LOADMORE"
                      label="Load more..."
                      v-if="!isSearching && hasMore"
                    ></el-option>
                  </el-select>
                </template>
                <template v-if="data.input_type == 'EMAIL'">
                  <el-input
                    type="email"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateEmail"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'SINGLE_LINE_TEXT'">
                  <el-input
                    type="text"
                    @change="checkMaxMinLen"
                    :minlength="data.properties.minlength_value"
                    :maxlength="data.properties.maxlength_value"
                    placeholder="Default Value"
                    v-model="data.default_value"
                  ></el-input>
                  <br />
                </template>
                <template v-if="data.input_type == 'MULTI_LINE_TEXT'">
                  <el-input
                    type="text"
                    @change="checkMaxMinLen"
                    :minlength="data.properties.minlength_value"
                    :maxlength="data.properties.maxlength_value"
                    placeholder="Default Value"
                    v-model="data.default_value"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'PASSWORD'">
                  <el-input
                    type="text"
                    @change="checkMaxMinLen"
                    :minlength="data.properties.minlength_value"
                    :maxlength="data.properties.maxlength_value"
                    placeholder="Default Value"
                    v-model="data.default_value"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'MASKED'">
                  <el-input
                    type="text"
                    @change="checkMaxMinLen"
                    :minlength="data.properties.minlength_value"
                    :maxlength="data.properties.maxlength_value"
                    placeholder="Default Value"
                    v-model="data.default_value"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'PHONE'">
                  <el-input
                    type="number"
                    @change="checkMaxMinLen"
                    :minlength="data.properties.minlength_value"
                    :maxlength="data.properties.maxlength_value"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validatePhone"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'CURRENCY'">
                  <el-input
                    type="number"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateCurrency"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'FAX'">
                  <el-input
                    type="number"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateFax"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'ZIP_CODE'">
                  <el-input
                    type="number"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateZipCode"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'NUMBER'">
                  <el-input
                    type="number"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateNumber"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'DECIMAL'">
                  <el-input
                    type="number"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateDecimal"
                  ></el-input>
                </template>
                <template v-if="data.input_type == 'PERCENT'">
                  <el-input
                    type="number"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    @blur="validateCurrency"
                  ></el-input>
                </template>
                <!-- //Gayatri Start -->

                <!-- <template v-if="data.input_type == 'RADIO'">
                <el-radio v-model="data.default_value">Default Value</el-radio>
              </template> -->
                <template v-if="data.input_type == 'TIME' && !data.is_fixed">
                  <el-time-picker
                    v-model="data.default_value"
                    :disabled="
                      data.properties.autofill_time_response ||
                      data.properties.defaultTodayTime
                    "
                    prefix-icon="el-icon-time"
                    :picker-options="{
                      format: 'HH:mm:ss',
                    }"
                    :placeholder="data.placeholder || data.label"
                    :value-format="'HH:mm:ss'"
                    class="eb-ctm-input-time"
                  >
                    placeholder="Default Value"
                  </el-time-picker>
                  <el-checkbox
                    v-model="data.properties.defaultTodayTime"
                    @change="handleCurrentTimeChange"
                    :disabled="data.properties.autofill_time_response"
                    >current Time</el-checkbox
                  >
                </template>
                <template v-if="data.input_type == 'TIME' && data.is_fixed">
                  <el-time-select
                    :picker-options="getTimeProperties"
                    :placeholder="data.placeholder || data.label"
                    :style="getStyle"
                    prefix-icon="el-icon-time"
                    class="eb-ctm-input-time"
                    v-bind:class="{
                      'time-picker-item': true,
                      'smaller-than': true,
                    }"
                    format="HH:mm:ss"
                    v-model="data.default_value"
                  >
                  </el-time-select>
                  <el-checkbox
                    v-if="data.input_type == 'TIME' && !data.is_fixed"
                    v-model="data.properties.defaultTodayTime"
                    @change="handleCurrentTimeChange"
                    :disabled="data.properties.autofill_time_response"
                    >current Time</el-checkbox
                  >
                </template>
                <template v-if="data.input_type == 'CHECKBOX'">
                  <el-checkbox v-model="data.default_value"
                    >Default Value</el-checkbox
                  >
                </template>
                <template v-if="data.input_type == 'RADIO_BUTTON_GROUP'">
                  <el-select
                    clearable
                    placeholder="Default Value"
                    v-model="data.default_value"
                  >
                    <el-option
                      v-for="item in data.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-if="data.input_type == 'CHECKBOX_GROUP'">
                  <div>
                    <el-select
                      multiple
                      clearable
                      :placeholder="'Minimum value is ' + data.min_selection"
                      v-model="data.default_value"
                    >
                      <el-option
                        v-for="item in data.options"
                        :disabled="
                          data &&
                          data.default_value &&
                          data.default_value.length === data.max_selection
                        "
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </template>
                <template v-if="data.input_type == 'DATE'">
                  <el-date-picker
                    placeholder="Default Value"
                    v-model="data.default_value"
                    :disabled="
                      data.properties.autofill_response ||
                      data.properties.defaultTodayDate
                    "
                  >
                  </el-date-picker>
                  <el-checkbox
                    v-model="data.properties.defaultTodayDate"
                    :disabled="data.properties.autofill_response"
                    >Today Date</el-checkbox
                  >
                </template>
                <template v-if="data.input_type == 'DATE_TIME'">
                  <el-date-picker
                    type="datetime"
                    placeholder="Default Value"
                    v-model="data.default_value"
                    :disabled="
                      data.properties.autofill_datetime_response ||
                      data.properties.defaultTodayDateTime
                    "
                  >
                  </el-date-picker>
                  <el-checkbox
                    v-model="data.properties.defaultTodayDateTime"
                    @change="
                      handleTodayDateTimeChange(
                        data.properties.defaultTodayDateTime
                      )
                    "
                    :disabled="data.properties.autofill_datetime_response"
                    >Today Date and Time</el-checkbox
                  >
                </template>
                <template v-if="data.input_type == 'DATE_TIME_RANGE'">
                  <span class="mr-1">Default Value</span>
                  <el-date-picker
                    type="datetimerange"
                    v-model="data.default_value"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  >
                  </el-date-picker>
                </template>
                <template v-if="data.input_type == 'DATE_RANGE'">
                  <el-date-picker
                    type="daterange"
                    v-model="data.default_value"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  >
                  </el-date-picker>
                </template>

                <!-- <template v-if="data.input_type == 'YES_OR_NO'">
                <span class="mr-1"> Default</span>
                <el-radio-group v-model="data.default_value">
                  <el-radio :label="3">Yes</el-radio>
                  <el-radio :label="6">No</el-radio>
                </el-radio-group>
              </template> -->
                <!-- end -->
                <template v-if="data.input_type == 'WEEKDAYS'">
                  <el-select
                    placeholder="Default value"
                    v-model="data.default_value"
                    :multiple="data.allow_multiple"
                    :multiple-limit="data.max_value"
                    collapse-tags
                    clearable
                  >
                    <el-option
                      v-for="(option, index) of options"
                      :value="option.value"
                      :label="option.name"
                      :key="index"
                    ></el-option>
                  </el-select>
                </template>
                <template v-if="data.input_type == 'TIME_RANGE'">
                  <el-time-picker
                    is-range
                    range-separator="To"
                    start-placeholder="Default start date"
                    prefix-icon="el-icon-time"
                    class="eb-ctm-input-time"
                    end-placeholder="default end time"
                    v-model="data.default_value"
                    style="width: 90%"
                  >
                  </el-time-picker>
                </template>
                <template v-if="data.input_type == 'RICH_TEXT'">
                  <el-button
                    style="cursor: pointer"
                    @click="openCkeditorPopup"
                    size="mini"
                  >
                    configure
                  </el-button>
                </template>
              </span>
            </div>
            <div
              class="test"
              v-if="
                data.input_type === 'LIST' ||
                data.input_type === 'SELECT' ||
                data.input_type === 'MULTI_SELECT'
              "
            >
              <span> Default Value:</span>
              <span
                :style="
                  hasError
                    ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                    : ''
                "
              >
                <template v-if="data.input_type == 'LIST'">
                  <el-select
                    clearable
                    placeholder="Default Value"
                    v-model="data.default_value"
                  >
                    <el-option
                      v-for="(item, index) of data.list_data"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-if="data.input_type == 'SELECT'">
                  <el-select
                    clearable
                    placeholder="Default Value"
                    v-model="data.default_value"
                  >
                    <el-option
                      v-for="item in data.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-if="data.input_type == 'PHONE_COUNTRY_CODE'">
                  <div class="vue-phone-validator">
                    <VuePhoneNumberInput
                      v-if="data.countryDisable == true"
                      :onlyCountries="[data.defaultCountry]"
                      :default-country-code="data.defaultCountry"
                      required
                      v-model="data.default_value"
                      @update="onUpdate"
                    />

                    <VuePhoneNumberInput
                      v-else
                      required
                      v-model="data.default_value"
                      :default-country-code="data.defaultCountry"
                      @update="onUpdate"
                    />
                  </div>
                </template>
                <template v-if="data.input_type == 'MULTI_SELECT'">
                  <el-select
                    multiple
                    clearable
                    :placeholder="'Minimum value is ' + data.min_selection"
                    v-model="data.default_value"
                  >
                    <el-option
                      v-for="item in data.options"
                      :key="item"
                      :label="item"
                      :value="item"
                      :disabled="
                        data &&
                        data.default_value &&
                        data.default_value.length === data.max_selection &&
                        !data.default_value.includes(item)
                      "
                    >
                    </el-option>
                  </el-select>
                </template>
              </span>
            </div>
          </template>
          <template v-if="data.inputType == 'CHECKBOX'">
            <span>Checkbox Values:</span><br />
            <el-input
              placeholder="Enter checked value"
              v-model="data.properties.checkedLabel"
              style="width: 180px; margin-right: 5px"
            ></el-input>
            <el-input
              placeholder="Enter unchecked value"
              v-model="data.properties.unCheckedLabel"
              style="width: 180px"
            ></el-input
            ><br />
          </template>
          <template style="margin-bottom: 5px">
            <el-checkbox
              v-model="data.apply_default_at_end"
              v-if="data.default_mapped_field || data.default_value"
            >
              Apply default value while saving
            </el-checkbox>
            <br />
          </template>
          <template>
            <div
              class="test mt-3"
              v-if="
                data.input_type !== 'HTML_CONTENT' &&
                data.input_type !== 'CALENDER'
              "
            >
              <span
                >Default Value on Table
                <el-tooltip
                  content="This value will display in the table when the field has no data."
                  ><i class="el-icon-info"></i></el-tooltip
              ></span>
              <el-input
                v-model="data.properties.tableDefaultValue"
                v-if="
                  data.input_type !== 'HTML_CONTENT' &&
                  data.input_type !== 'CALENDER'
                "
                placeholder="Enter value here"
              ></el-input>
            </div>
            <br />
          </template>
          <!-- ML prediction inputs -->
          <template>
            <div
              class="Predit-Value test"
              v-if="
                data.input_type !== 'HTML_CONTENT' &&
                data.input_type !== 'CALENDER'
              "
            >
              <span> Predict value from:</span>
              <el-select
                placeholder="Predict Value from"
                v-model="data.predict_input_fields"
                v-if="
                  data.input_type !== 'CHECKBOX_GROUP' &&
                  data.input_type !== 'HTML_CONTENT' &&
                  data.input_type !== 'CALENDER'
                "
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="(option, index) of getPredictableFields"
                  :value="option.key"
                  :label="option.label"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </template>
          <template v-if="data.input_type == 'RICH_TEXT'">
            <div class="Predit-Value test">
              <span> Select default Font:</span>
              <el-select
                placeholder="select default font"
                v-model="data.default_font_type"
                clearable
              >
                <el-option
                  v-for="(option, index) of getAvailableFontTypes"
                  :value="option.value"
                  :label="option.name"
                  :key="index"
                >
                  <p :style="'font-family:' + option.value">
                    {{ option.name }}
                  </p>
                </el-option>
              </el-select>
            </div>
            <div class="Predit-Value test">
              <span> Select default size:</span>
              <el-select
                placeholder="select default font size"
                v-model="data.default_font_size"
                clearable
              >
                <el-option
                  v-for="(option, index) of getAvailableFontSizes"
                  :value="option + 'px'"
                  :label="option"
                  :key="index"
                  :style="{
                    minHeight: option > 36 ? option + 'px' : '36px',
                  }"
                >
                  <p
                    :style="{
                      fontSize: option + 'px',
                      lineHeight: option + 'px',
                      margin: 0,
                    }"
                  >
                    {{ option }}
                  </p>
                </el-option>
              </el-select>
            </div>
          </template>
          <!-- ML prediction inputs -->
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
        </template>
        <template
          v-if="
            (!data.is_global_variable && data.input_type === 'CHECKBOX') ||
            data.input_type === 'RADIO'
          "
        >
          <!-- <div class="d-flex">
            <span>Select Group:</span>
            <el-select v-model="data.group_key" @change="updateEntityFields" :default-first-option="true">
              <template>
                <el-option v-for="(group, index) of checkBoxGroupArray" :value="group.key" :key="index"
                  :label="group.label"></el-option>
              </template>
            </el-select>
            <el-tooltip placement="top-left" content="Add Group">
              <el-button
                @click="openAddGroupPopUp"
                class="ml-1"
                type="primary"
                size="mini"
              >
                <i class="el-icon-circle-plus-outline"></i
              ></el-button>
            </el-tooltip>

          </div>
          <template
            v-if="data.input_type === 'CHECKBOX' && !data.is_global_variable && data.group_key && currentGroupIndex > -1">
            <div class="d-flex">
              <span>Maximum Value:</span>
              <span>
                <el-input type="number" @blur="checkMaxMin" placeholder="Maximum Value"
                  v-model="checkBoxList.max_value"></el-input>
              </span>
            </div>
            <div  class="d-flex">
              <span>Minimum Value:</span>
              <span>
                <el-input type="number"   placeholder="Minimum Value" v-model="checkBoxGroupObjects.min_value"></el-input>
              </span>
            </div>
            <div class="d-flex">
              <span>Maximum Value:</span>
              <span>
                <el-input type="number"   placeholder="Maximum Value" v-model="checkBoxGroupObjects.max_value"></el-input>
              </span>
            </div>
            <div  class="d-flex">
              <span>Show Label:</span>
              <span>
                <el-checkbox type="number" v-model="checkBoxGroupObjects.show_checkbox_label"></el-checkbox>
              </span>
            </div>
          </template> -->
        </template>
        <template v-if="!data.is_global_variable && data.input_type === 'TIME'">
          <div class="d-flex" v-if="!data.is_global_variable">
            <span> Allowed Time: </span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select v-model="data.properties.time_settings" clearable>
                  <el-option
                    v-for="(time, index) of timeSettings"
                    :label="time.name"
                    :value="time.name"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.time_settings === 'Range'">
              Time Map with:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-if="data.properties.time_settings === 'Range'"
                  v-model="data.properties.selected_time_mapped_field"
                  :clearable="true"
                  :disabled="isTimeMapWithDisabled"
                >
                  <el-option
                    v-for="(option, index) of this.allFieldsData.filter(
                      (e) => e.inputType == 'TIME_RANGE'
                    )"
                    :value="option.key"
                    :label="option.label"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.time_settings === 'Range'">
              Start From:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-time-picker
                  v-model="data.properties.time_start_range"
                  v-if="data.properties.time_settings === 'Range'"
                  placeholder="Select Time"
                  :disabled="isTimeFieldDisabled"
                >
                </el-time-picker>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.time_settings === 'Range'">
              End On:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-time-picker
                  v-model="data.properties.time_end_range"
                  v-if="data.properties.time_settings === 'Range'"
                  placeholder="Select Time"
                  :disabled="isTimeFieldDisabled"
                >
                </el-time-picker>
              </template>
            </span>
          </div>
        </template>
        <template
          v-if="!data.is_global_variable && data.input_type === 'DATE_RANGE'"
        >
          <div class="d-flex" v-if="!data.is_global_variable">
            <span> Allowed Dates: </span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-model="data.properties.daterange_settings"
                  clearable
                >
                  <el-option
                    v-for="(date, index) of dateRangeSettings"
                    :label="date.name"
                    :value="date.name"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </template>
            </span>
          </div>
          <el-checkbox
            v-model="data.properties.show_weekdays"
            v-if="
              data.properties.daterange_settings &&
              data.properties.daterange_settings.length > 0 &&
              data.properties.daterange_settings != 'WeekDays' &&
              data.properties.daterange_settings != 'All Dates'
            "
            label="Weekdays"
          ></el-checkbox>
          <div
            class="d-flex"
            v-if="
              (!data.is_global_variable &&
                data.properties.daterange_settings === 'WeekDays') ||
              (data.properties.show_weekdays &&
                data.properties.daterange_settings != 'All Dates')
            "
          >
            <span>WeekDays:</span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-model="data.properties.selected_weekdays"
                  :clearable="false"
                  multiple
                  :rules="[
                    {
                      required: data.properties.show_weekdays,
                      message: 'Please select at least one option',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-option
                    v-for="(day, index) in weekdays"
                    :key="index"
                    :label="day.label"
                    :value="day.value"
                  ></el-option>
                </el-select>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.daterange_settings === 'Range'">
              Start From:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-date-picker
                  v-model="data.properties.date_strat_range"
                  v-if="data.properties.daterange_settings === 'Range'"
                  type="date"
                  placeholder="select"
                >
                </el-date-picker>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.daterange_settings === 'Range'">
              End On:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-date-picker
                  v-model="data.properties.date_end_range"
                  v-if="data.properties.daterange_settings === 'Range'"
                  type="date"
                  placeholder="select"
                >
                </el-date-picker>
              </template>
            </span>
          </div>
        </template>
        <template v-if="!data.is_global_variable && data.input_type === 'DATE'">
          <div class="d-flex" v-if="!data.is_global_variable">
            <span> Allowed Dates: </span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select v-model="data.properties.date_settings" clearable>
                  <el-option
                    v-for="(date, index) of dateSettings"
                    :label="date.name"
                    :value="date.name"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </template>
            </span>
          </div>

          <el-checkbox
            v-model="data.properties.show_weekdays"
            v-if="
              data.properties.date_settings &&
              data.properties.date_settings.length > 0 &&
              data.properties.date_settings != 'WeekDays' &&
              data.properties.date_settings != 'All Dates'
            "
            label="Weekdays"
          ></el-checkbox>

          <div
            class="d-flex"
            v-if="
              (!data.is_global_variable &&
                data.properties.date_settings === 'WeekDays') ||
              (data.properties.show_weekdays &&
                data.properties.date_settings != 'All Dates')
            "
          >
            <span>WeekDays:</span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-model="data.properties.selected_weekdays"
                  :clearable="false"
                  multiple
                >
                  <el-option
                    v-for="(day, index) in weekdays"
                    :key="index"
                    :label="day.label"
                    :value="day.value"
                  ></el-option>
                </el-select>
              </template>
            </span>
          </div>
          <div
            class="d-flex"
            v-if="
              (!data.is_global_variable &&
                data.properties.date_settings === 'WeekDays') ||
              (data.properties.show_weekdays &&
                data.properties.date_settings != 'All Dates')
            "
          >
            <span>WeekDays map with: </span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-model="data.properties.selected_weekdays_mapped_field"
                  :clearable="true"
                  :disabled="isMapWithDisabled"
                >
                  <el-option
                    v-for="(option, index) of this.allFieldsData.filter(
                      (e) => e.inputType == 'WEEKDAYS'
                    )"
                    :value="option.key"
                    :label="option.label"
                    :key="index"
                  ></el-option>
                </el-select>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.date_settings === 'Range'">
              Start From:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-date-picker
                  v-model="data.properties.date_strat_range"
                  v-if="data.properties.date_settings === 'Range'"
                  type="date"
                  placeholder="select"
                >
                </el-date-picker>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.date_settings === 'Range'">
              End On:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-date-picker
                  v-model="data.properties.date_end_range"
                  v-if="data.properties.date_settings === 'Range'"
                  type="date"
                  placeholder="select"
                >
                </el-date-picker>
              </template>
            </span>
          </div>
          <!-- <div class="d-flex" v-if="!data.is_global_variable">
            <span> Allowed Days: </span>
            <span :style="
              hasError
                ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                : ''
            ">
              <template>
                <el-select multiple v-model="data.properties.day_settings" clearable>
                  <el-option v-for="(date, index) of daySettings" :label="date.name" :value="date.name" :key="index">
                  </el-option>
                </el-select>
              </template>
            </span>
          </div> -->
        </template>
        <template
          v-if="!data.is_global_variable && data.input_type === 'DATE_TIME'"
        >
          <div class="d-flex" v-if="!data.is_global_variable">
            <span> Allowed Date_time: </span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-model="data.properties.datetime_settings"
                  clearable
                >
                  <el-option
                    v-for="(date, index) of datetime_Settings"
                    :label="date.name"
                    :value="date.name"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </template>
            </span>
          </div>
          <el-checkbox
            v-model="data.properties.show_weekdays"
            v-if="
              data.properties.datetime_settings &&
              data.properties.datetime_settings.length > 0 &&
              data.properties.datetime_settings != 'WeekDays' &&
              data.properties.datetime_settings != 'All Date_time'
            "
            label="Weekdays"
          ></el-checkbox>
          <div
            class="d-flex"
            v-if="
              (!data.is_global_variable &&
                data.properties.datetime_settings === 'WeekDays') ||
              (data.properties.show_weekdays &&
                data.properties.datetime_settings != 'All Date_time')
            "
          >
            <span>WeekDays:</span>
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-select
                  v-model="data.properties.selected_weekdays"
                  :clearable="false"
                  multiple
                  :rules="[
                    {
                      required: data.properties.show_weekdays,
                      message: 'Please select at least one option',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-option
                    v-for="(day, index) in weekdays"
                    :key="index"
                    :label="day.label"
                    :value="day.value"
                  ></el-option>
                </el-select>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.datetime_settings === 'Range'">
              Start From:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-date-picker
                  v-model="data.properties.date_strat_range"
                  v-if="data.properties.datetime_settings === 'Range'"
                  type="date"
                  placeholder="select"
                >
                </el-date-picker>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.datetime_settings === 'Range'">
              End On:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-date-picker
                  v-model="data.properties.date_end_range"
                  v-if="data.properties.datetime_settings === 'Range'"
                  type="date"
                  placeholder="select"
                >
                </el-date-picker>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.datetime_settings === 'Range'">
              Start From:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-time-picker
                  v-model="data.properties.time_start_range"
                  v-if="data.properties.datetime_settings === 'Range'"
                  placeholder="Select Time"
                >
                </el-time-picker>
              </template>
            </span>
          </div>
          <div class="d-flex" v-if="!data.is_global_variable">
            <span v-if="data.properties.datetime_settings === 'Range'">
              End On:</span
            >
            <span
              :style="
                hasError
                  ? 'border: 1px solid red;border-radius: 5px; font-size: 14px;'
                  : ''
              "
            >
              <template>
                <el-time-picker
                  v-model="data.properties.time_end_range"
                  v-if="data.properties.datetime_settings === 'Range'"
                  placeholder="Select Time"
                >
                </el-time-picker>
              </template>
            </span>
          </div>
        </template>
        <template
          v-if="
            (!data.is_global_variable && data.input_type === 'NUMBER') ||
            data.input_type === 'CURRENCY' ||
            data.input_type === 'DECIMAL' ||
            data.input_type === 'PERCENT'
          "
        >
          <div class="test">
            <span>Maximum Value:</span>
            <span>
              <el-input
                placeholder="Maximum Value"
                v-model="data.max_value"
              ></el-input>
            </span>
          </div>
          <br />
          <div class="test">
            <span>Minimum Value:</span>
            <span>
              <el-input
                placeholder="Minimum Value"
                v-model="data.min_value"
              ></el-input>
            </span>
          </div>
          <br />
        </template>

        <template
          v-if="
            (!data.is_global_variable &&
              data.input_type === 'SINGLE_LINE_TEXT') ||
            data.input_type === 'PASSWORD' ||
            data.input_type == 'PHONE' ||
            data.input_type == 'MULTI_LINE_TEXT'
          "
        >
          <div class="test">
            <span>Maximum Length:</span>
            <span>
              <el-input
                placeholder="Maximum Length"
                @change="checkMaxMinLen"
                @blur="checkMaxMin"
                v-model="data.properties.maxlength_value"
              ></el-input>
            </span>
            <br />
          </div>
          <div class="test">
            <span>Minimum Length:</span>
            <span>
              <el-input
                placeholder="Minimum Length"
                @change="checkMaxMinLen"
                @blur="checkMaxMin"
                v-model="data.properties.minlength_value"
              ></el-input>
            </span>
          </div>
          <br />
        </template>
        <template
          v-if="!data.is_global_variable && data.input_type === 'MASKED'"
        >
          <div class="d-flex">
            <span>Masked Type:</span>
            <span>
              <el-select v-model="data.properties.masked_type">
                <el-option
                  v-for="item in masked_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >{{ item.label }}
                </el-option>
              </el-select>
            </span>
          </div>
        </template>
        <template
          v-if="!data.is_global_variable && data.input_type === 'NUMBER'"
        >
          <div class="test">
            <span>Number Type:</span>
            <span>
              <el-select v-model="data.properties.number_type" clearable>
                <el-option
                  v-for="item in number_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >{{ item.label }}
                </el-option>
              </el-select>
            </span>
          </div>
        </template>
        <template
          v-if="
            !data.is_global_variable &&
            data.input_type == 'FILE' &&
            !data.allow_multiple
          "
        >
          <el-checkbox v-model="data.use_ai" @change="handleAiCheck()"
            >Use AI to auto fill fields</el-checkbox
          >
          <div class="d-flex" v-if="data.use_ai">
            <span> Select fields </span>
            <span>
              <el-select
                v-model="data.ai_autofill_fields"
                multiple
                collapse-tags
                clearable
                @change="handleAutofillClick()"
              >
                <el-option
                  v-if="
                    data.ai_autofill_fields &&
                    data.ai_autofill_fields.length !== supportedFields.length
                  "
                  :key="'select-all'"
                  :label="'Select All'"
                  :value="'selectAllOptions'"
                >
                  <strong>Select All</strong>
                </el-option>
                <el-option
                  v-if="
                    data.ai_autofill_fields && data.ai_autofill_fields.length
                  "
                  :key="'unselect-all'"
                  :label="'Unselect All'"
                  value="unselectAllOptions"
                >
                  <strong>Unselect All</strong>
                </el-option>
                <el-option
                  v-for="item in supportedFields"
                  :key="item.key"
                  :label="item.label"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </span>
          </div>
          <div class="d-flex" v-if="data.use_ai">
            <span> Prompt (Optional)</span>
          </div>
          <div v-if="data.use_ai">
            <span>
              <el-input
                v-model="data.ai_settings.userPrompt"
                style="width: 100%"
                type="textarea"
                :autosize="{ minRows: 2 }"
                :placeholder="'Enter Your Prompt'"
              ></el-input>
            </span>
          </div>
        </template>

        <p v-if="maxMinErr" style="color: red; font-size: 14px">
          {{ maxMinErrMsg }}
        </p>
      </div>
    </el-scrollbar>
    <!-- <el-dialog>

    </el-dialog> -->
    <dialog-component
      title="Icon list"
      :visible.sync="addiconlist"
      :width="getIsMobile ? '100%' : '50%'"
      :before-close="resetIconsPopup"
    >
      <ElIconsList></ElIconsList>
    </dialog-component>
    <dialog-component
      :title="'Default Value'"
      :visible="ckEditorDialog"
      :containerWidth="'50%'"
      @before-close="resetCkeditorPopup"
    >
      <div style="padding: 20px 10px">
        <ckeditor :config="editorConfig" v-model="data.default_value">
        </ckeditor>
      </div>
    </dialog-component>
  </section>
</template>

<script>
// import { ElIcon } from 'element-ui';
import ElIconsList from "@/components/widgets/ElementIconsList.vue";
import templateConstants from "../../../constants/lg-en/templates";
import { bus } from "../../../main";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import { postAPICall } from "@/helpers/httpHelper";
import fonts from "@/constants/fonts.js";

// tharuni end
export default {
  name: "templates-formComponents-FieldProperties",
  props: [
    "field",
    "fieldsData",
    "sectionLabel",
    "checkBoxGroupArray",
    "checkBoxGroupObjects",
    "currentGroupIndex",
    "allFieldsData",
  ],
  mixins: [CustomDashboardConfig],
  // components: {
  //   // ElIcon
  //   EntityFilters: () => import("./EntityFilters"),
  // },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    getSegregatedNumberFields() {
      let requiredFields = [];
      this.fieldsData.forEach((requiredField) => {
        if (requiredField.inputType == "NUMBER") {
          requiredFields.push({
            label: requiredField.label,
            key: requiredField.key,
          });
        }
      });
      return requiredFields;
    },
    getPredictableFields() {
      return this.allFieldsData.filter((e) => {
        if (this.allowedFieldTypes.indexOf(e.inputType) !== -1) {
          return true;
        }
      });
    },
    getAvailableFontTypes() {
      return fonts.fontTypes;
    },
    getAvailableFontSizes() {
      return fonts.fontSizes;
    },
    getDateFields() {
      return this.allFieldsData.filter((d) => {
        let fd = JSON.parse(JSON.stringify(d));
        if (
          fd.key !== this.data.key &&
          (fd.inputType == "DATE" ||
            (fd.input_type == "FORMULA" && fd.result_type == "DATE"))
        ) {
          return true;
        }
      });
    },
    getSameInputFields() {
      return this.allFieldsData.filter((d) => {
        let fd = JSON.parse(JSON.stringify(d));
        if (fd.inputType == "FORMULA" && fd.result_type) {
          fd.inputType = fd.result_type;
        }
        if (fd.key !== this.data.key) {
          let numberInputs = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"];
          if (
            numberInputs.includes(this.data.inputType) &&
            numberInputs.includes(fd.inputType)
          ) {
            return true;
          } else if (
            this.data.inputType == "FORMULA" &&
            numberInputs.includes(this.data.result_type) &&
            numberInputs.includes(fd.inputType)
          ) {
            return true;
          } else if (fd.inputType == this.data.inputType) {
            switch (fd.inputType) {
              case "ENTITY":
                return fd.entity_id == this.data.entity_id;
              default:
                return true;
            }
          }
          return false;
        }
      });
    },
    isMapWithDisabled() {
      return (
        this.data &&
        this.data.properties &&
        this.data.properties.selected_weekdays &&
        this.data.properties.selected_weekdays.length > 0
      );
    },
    // isWeekDaysDisabled() {
    //   return this.data.properties.selected_weekdays_mapped_field.length;
    // },
    // isTimeFieldDisabled() {
    //   return this.data.properties.selected_time_mapped_field.length;
    // },
    isTimeMapWithDisabled() {
      return (
        this.data.properties.time_start_range &&
        this.data.properties.time_end_range
      );
    },

    isTableField() {
      let found = this.allFieldsData.some((d) => {
        let fd = JSON.parse(JSON.stringify(d));
        if (
          fd.key == this.data.default_mapped_field &&
          fd.data_table_field_index > -1
        ) {
          this.data.default_mapped_field_parent = fd.data_table_key;
          return true;
        }
        return false;
      });
      return found;
    },
  },
  data() {
    return {
      viewOptions: [
        {
          value: "table_view",
          label: "Table",
        },
        {
          value: "card_view",
          label: "Card",
        },
        {
          value: "Accordion_view",
          label: "Accordion",
        },
      ],
      segregatedNumberFields: [],
      properties: {
        selected_weekdays: [],
        show_weekdays: false,
      },
      timeSettings: [
        {
          name: "Range",
        },
      ],
      weekdays: [
        { label: "Sunday", value: 0 },
        { label: "Monday", value: 1 },
        { label: "Tuesday", value: 2 },
        { label: "Wednesday", value: 3 },
        { label: "Thursday", value: 4 },
        { label: "Friday", value: 5 },
        { label: "Saturday", value: 6 },
      ],
      dateRangeSettings: [
        {
          name: "All Dates",
        },

        {
          name: "Past Dates",
        },
        {
          name: "Future Dates",
        },

        {
          name: "Range",
        },
        {
          name: "WeekDays",
        },
      ],
      dateSettings: [
        {
          name: "All Dates",
        },

        {
          name: "Past Dates",
        },
        {
          name: "Future Dates",
        },

        {
          name: "Range",
        },
        {
          name: "WeekDays",
        },
      ],
      datetime_Settings: [
        {
          name: "All Date_time",
        },

        {
          name: "Past Dates",
        },
        {
          name: "Future Dates",
        },
        {
          name: "Range",
        },
        {
          name: "WeekDays",
        },
      ],
      // daySettings: [
      //   {
      //     name: "Sunday",
      //   },
      //   {
      //     name: "Monday",
      //   },
      //   {
      //     name: "Tuesday",
      //   },
      //   {
      //     name: "Wednesday",
      //   },
      //   {
      //     name: "Thursday",
      //   },
      //   {
      //     name: "Friday",
      //   },
      //   {
      //     name: "Saturday",
      //   }
      // ],
      searchIconResult: [],
      activeName: "1",
      borderSize: 0,
      borderColor: "",
      defaultTodayDate: false,
      settings: [
        {
          title: "",
        },
      ],
      masked_type: [
        {
          name: "SSN",
          value: "SSN",
        },
        {
          name: "CREDIT CARD",
          value: "CREDIT CARD",
        },
      ],
      number_type: [
        {
          name: "DECIMAL",
          value: "DECIMAL",
        },
        {
          name: "PERCENTAGE",
          value: "PERCENTAGE",
        },
      ],

      borderTypes: [
        {
          name: "None",
          value: "none",
          demo: "<span style='border:none;'>abc</span>",
        },
        {
          name: "Fill",
          value: "solid",
          demo: "<span style='border:solid;'>abc</span>",
        },
        {
          name: "Dotted",
          value: "dotted",
          demo: "<span style='border:dotted;'>abc</span>",
        },
        {
          name: "Dashed",
          value: "dashed",
          demo: "<span style='border:dashed;'>abc</span>",
        },
        {
          name: "double",
          value: "Double",
          demo: "<span style='border:double;'>abc</span>",
        },
        {
          name: "3D Border groove",
          value: "groove",
          demo: "<span style='border:groove;'>abc</span>",
        },
        {
          name: "3D Border ridge",
          value: "ridge",
          demo: "<span style='border:ridge;'>abc</span>",
        },
        {
          name: "3D Border outset",
          value: "outset",
          demo: "<span style='border:outset;'>abc</span>",
        },
        {
          name: "3D Border inset",
          value: "inset",
          demo: "<span style='border:inset;'>abc</span>",
        },
      ],
      propertiesList: [
        {
          name: "None",
          value: "",
          // demo: "<span style='border:outset;'>abc</span>"
        },
        {
          name: "Read Only",
          value: "READ ONLY",
          // demo: "<span style='border:outset;'>abc</span>"
        },
        {
          name: "DISABLED",
          value: "DISABLED",
          // demo: "<span style='border:inset;'>abc</span>"
        },
        {
          name: "Hide",
          value: "Hide",
        },
      ],
      data: {},
      iconsArray: [],
      errorMsg: "",
      maxMinErrMsg: "",
      hasError: false,
      maxMinErr: false,
      addiconlist: false,
      groups: [],
      checkBoxGrpArr: [],
      group_key: "",
      checkBoxGroupArr: {},
      showAddData: false,
      addGroup: {
        label: "",
        key: "",
        max_value: 0,
        min_value: 0,
      },
      addNewPopup: false,
      //checkBoxList:this.checkBoxGroupObjects
      options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" },
      ],
      countryCode: {},
      editorConfig: ckEditorConfig,
      ckEditorDialog: false,
      mentionableFields: [],
      allowedFieldTypes: [
        "SINGLE_LINE_TEXT",
        "MULTI_LINE_TEXT",
        "DATE",
        "SELECT",
        "NUMBER",
        "MULTI_SELECT",
        "CURRENCY",
        "YES_OR_NO",
        "CHECKBOX",
        "CHECKBOX_GROUP",
        "FORMULA",
        "ENTITY",
        "WEEKDAYS",
        "RADIO",
        "RADIO_BUTTON_GROUP",
        "LIST",
        "TIME",
        "GLOBAL_VARIABLE",
        "DATE_TIME",
        "AGGREGATE_FUNCTION",
        "PHONE_COUNTRY_CODE",
        "STAR_RATING",
        "PAY_BUTTON",
        "AUTO_INCREMENT_NUMBER",
        "ENTITY_VARIABLE",
        "SIGNATURE",
        "HTML_CONTENT",
        "CALENDAR",
      ],
      allowingFieldTypes: [
        "RADIO_BUTTON_GROUP",
        "SINGLE_LINE_TEXT",
        "EMAIL",
        "PASSWORD",
        "MASKED",
        "PHONE",
        "FAX",
        "NUMBER",
        "ZIP_CODE",
        "CURRENCY",
        "DECIMAL",
        "PERCENT",
        "SELECT",
        "MULTI_LINE_TEXT",
        "RICH_TEXT",
        "DATE_TIME_RANGE",
        "DATE_RANGE",
        "SIGNATURE",
        "ENTITY_VARIABLE",
        "ENTITY",
        "FORMULA",
        "AGGREGATE_FUNCTION",
        "DATE",
        "TIME",
        "DATE_TIME",
        "YES_OR_NO",
        "CHECKBOX",
        "CHECKBOX_GROUP",
        "MULTI_SELECT",
        "LIST",
        "RADIO",
        "PHONE_COUNTRY_CODE",
        "TIME_RANGE",
        "WEEKDAYS",
        "IMAGE",
        "HYPER_LINK",
        "HTML_CONTENT",
        "CALENDER",
        "DIVISION",
      ],
      entitiesData: [],
      isSearching: false,
      hasMore: false,
      optionsLoading: false,
      search_string: "",
      page: 1,
      limit: 500,
      currentEntity: {},
      supportedFields: [],
      supportedFieldsKeyObject: {},
      dateValueOptions: [
        {
          name: "Week day",
          key: "WEEK_DAY",
        },
        {
          name: "Remaining days of week",
          key: "CR_WEEK_DAYS",
        },
        {
          name: "Date",
          key: "MONTH_DAY",
        },
        {
          name: "Remaining days of month",
          key: "CR_MONTH_DAYS",
        },
        {
          name: "Days of month",
          key: "C_MONTH_DAYS",
        },
        {
          name: "Remaining days of half year",
          key: "CR_HALF_YEAR_DAYS",
        },
        {
          name: "Remaining days of quarter",
          key: "CR_QUARTER_DAYS",
        },
        {
          name: "Remaining days of year",
          key: "CR_YEAR_DAYS",
        },
        {
          name: "Remaining days of custom range",
          key: "CR_CUSTOM_DAYS",
        },
      ],
    };
  },

  mounted() {
    if (!this.field.table_data_view_type) {
      this.field.table_data_view_type = "table_view";
    }
    this.data = this.field;
    if (this.data && !this.data.group) {
      this.data["group"] = {
        label: "",
        key: "",
        max_value: 0,
        min_value: 0,
      };
    }
    if (this.data && this.fieldsData) {
      let filterdFields = this.fieldsData.filter(
        (f) => f.input_type == this.data.input_type
      );
      filterdFields.forEach((field) => {
        if (field.group && field.group.key) {
          if (this.groups.length) {
            let gIndex = -1;
            gIndex = this.groups.findIndex(
              (g) =>
                g.key == field.group.key &&
                field.input_type == this.data.input_type
            );
            if (gIndex <= -1) {
              this.groups.push(field.group);
            }
          } else {
            this.groups.push(field.group);
          }
        }
      });
    }
    if (this.data.input_type == "RICH_TEXT") {
      this.configureCkeditor();
    }
    bus.$on("groupInfo", (data) => {
      this.checkBoxGroupArr = data;
      this.groups.push(data);
      this.$set(this.data.group, "key", data.key);
    });
    bus.$on("fieldUpdate", (data) => {
      this.data = data;
    });
    // tharuni start
    bus.$on("onSelectedIcon", (data) => {
      this.data.properties.el_icon = data;
    });
    // tharuni end

    // bus.$on("groupFieldUpdateOnChange",(data)=>{
    //   this.checkBoxGroupObjects =  data
    // })
    if (this.data?.inputType == "ENTITY") {
      this.fetchEntitiesDataForTable();
    }
    if (this.data.use_ai) {
      this.getSupportedFields();
    }
  },
  components: {
    ElIconsList,
    VuePhoneNumberInput: () => import("vue-phone-number-input"),
  },

  methods: {
    getTimeProperties() {
      if (
        this.data?.properties?.time_range &&
        this.data?.properties?.step_time
      ) {
        let properties = {};
        properties["start"] = moment(
          this.data?.properties?.time_range[0]
        ).format("HH:mm:ss");
        properties["end"] = moment(this.data?.properties?.time_range[1]).format(
          "HH:mm:ss"
        );
        if (
          this.data?.properties?.step_time == 60 ||
          this.data?.properties?.step_time == "60"
        ) {
          properties["step"] = "01:00:00";
        } else {
          properties["step"] = "00:" + this.data?.properties?.step_time + ":00";
        }
        return properties;
      }
      return {
        start: "00:00:00",
        step: "00:30:00",
        end: "23:59:00",
      };
    },
    handleClear() {
      this.isSearching = false;
      this.search_string = "";
      this.page = 1;
      this.fetchEntitiesDataForTable();
    },
    searchEntityData(query) {
      if (query) {
        this.isSearching = true;
        if (query.length > 0) {
          this.search_string = query;
          this.page = 1;
          this.fetchEntitiesDataForTable();
        }
      } else {
        this.isSearching = false;
        this.search_string = "";
        this.page = 1;
        this.fetchEntitiesDataForTable();
      }
    },
    async fetchEntitiesDataForTable() {
      this.optionsLoading = true;
      this.hasMore = false;
      let params = {
        entity_id: this.data.entity_id,
        template_fields_data: [],
        filters: this.mapDateFilters(
          this.removeOptionalFilters(
            this.addGlobalFilters(this.data.filters || [])
          )
        ),
        limit: this.limit,
        page: this.page,
        ignore_populate: true,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      try {
        const response = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        if (this.isSearching || !this.loadMore) {
          this.entitiesData = [];
        }
        this.loadMore = false;
        if (response) {
          this.hasMore = response.hasMore;
          this.entitiesData = [...this.entitiesData, ...response.data];
          this.currentEntity = response?.selectedEntity || {};
          this.optionsLoading = false;
        } else {
          this.optionsLoading = false;
        }
      } catch (e) {
        console.log("Error while fetching " + this.data.label + ": ", e);
        this.optionsLoading = false;
        this.loadMore = false;
      }
    },
    resetCkeditorPopup() {
      let labelAndFields = {};
      this.mentionableFields.map((e) => {
        labelAndFields[e.label] = e;
      });
      if (this.data.default_value) {
        this.data.default_value = this.data.default_value.replace(
          /\[\[(.*?)\]\]/g,
          (match, label) => {
            const template = labelAndFields[label];
            if (!template) {
              return "-";
            }
            return template ? `[[${template?.key}]]` : match;
          }
        );
      }
      this.ckEditorDialog = false;
    },
    openCkeditorPopup() {
      let keyAndFields = {};
      this.mentionableFields.map((e) => {
        keyAndFields[e.key] = e;
      });
      if (this.data.default_value) {
        this.data.default_value = this.data.default_value.replace(
          /\[\[(.*?)\]\]/g,
          (match, key) => {
            const template = keyAndFields[key];
            if (!template) {
              return "-";
            }
            return template ? `[[${template?.label}]]` : match;
          }
        );
      }
      this.ckEditorDialog = true;
    },
    configureCkeditor() {
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><span>[[{label}]]</span><span>&nbsp;</span>",
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
        },
      ];
      let notSupportedFields = [
        "IMAGE",
        "RICH_TEXT",
        "FILE",
        "PAY_BUTTON",
        "DATA_TABLE",
      ];
      let supportedFields = this.fieldsData.filter(
        (e) =>
          !notSupportedFields.includes(e.input_type) &&
          e.key !== this.data.key &&
          e.type != "CONTENT" &&
          e.label
      );
      this.mentionableFields = supportedFields
        .map((e) => {
          if (
            notSupportedFields.includes(e.input_type) ||
            e.key == this.data.key
          )
            return null;
          return {
            key: e.key,
            label: e.label,
            input_type: e.input_type,
            id: e.key,
          };
        })
        .filter((e) => e != null);
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.mentionableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    addiconsToFromTemplates() {
      this.addiconlist = true;
    },
    openAddGroupPopUp() {
      this.$emit("openAddNew");
    },
    changingDefaultValue(data) {
      console.log("adarsdatatdat", this.data.options, data);
    },
    validatePhone() {
      if (templateConstants.phonePattern.test(this.data.default_value)) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Phone Format";
        this.hasError = true;
      }
    },
    validateCurrency() {
      let fieldValue = parseFloat(this.data.default_value);
      let minValue = parseFloat(this.data.min_value);
      let maxValue = parseFloat(this.data.max_value);
      if (templateConstants.currencyPattern.test(this.data.default_value)) {
        if (this.data.max_value && this.data.min_value) {
          if (this.checkInBetween(fieldValue, maxValue, minValue)) {
            this.errorMsg = "";
            this.hasError = false;
          } else {
            this.errorMsg =
              "Value should range between " +
              this.data.max_value +
              " and " +
              this.data.min_value;
            this.hasError = true;
          }
        } else {
          this.errorMsg = "";
          this.hasError = false;
        }
      } else {
        // this.errorMsg = 'Accepts non-negative numbers with max two decimals.';
        this.hasError = true;
      }
    },
    validateDecimal() {
      let fieldValue = parseFloat(this.data.default_value);
      let minValue = parseFloat(this.data.min_value);
      let maxValue = parseFloat(this.data.max_value);
      if (templateConstants.decimalPattern.test(this.data.default_value)) {
        if (this.data.max_value && this.data.min_value) {
          if (this.checkInBetween(fieldValue, maxValue, minValue)) {
            this.errorMsg = "";
            this.hasError = false;
          } else {
            this.errorMsg =
              "Value should range between " +
              this.data.max_value +
              " and " +
              this.data.min_value;
            this.hasError = true;
          }
        } else {
          this.errorMsg = "";
          this.hasError = false;
        }
      } else {
        // this.errorMsg = 'Accepts positive and negative numbers with max two decimals.';
        this.hasError = true;
      }
    },
    validateZipCode() {
      let fieldValue = this.form[this.data.key];
      if (templateConstants.zipCodePattern.test(fieldValue)) {
        this.errorMsg = "Valid Code";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Code";
        this.hasError = true;
      }
    },
    validateFax() {
      if (templateConstants.faxPattern.test(this.data.default_value)) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Fax Format";
        this.hasError = true;
      }
    },
    validateNumber() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.number_type &&
        this.data.properties.number_type === "DECIMAL"
      ) {
        let fieldValue = parseFloat(this.form[this.data.key]);
        let minValue = parseFloat(this.data.min_value);
        let maxValue = parseFloat(this.data.max_value);
        if (templateConstants.decimalPattern.test(this.form[this.data.key])) {
          if (this.data.max_value && this.data.min_value) {
            if (this.checkInBetween(fieldValue, maxValue, minValue)) {
              this.errorMsg = "";
              this.hasError = false;
            } else {
              this.errorMsg =
                "Value should range between " +
                this.data.max_value +
                " and " +
                this.data.min_value;
              this.hasError = true;
            }
          } else {
            this.errorMsg = "";
            this.hasError = false;
          }
        } else {
          // this.errorMsg = 'Accepts positive and negative numbers with max two decimals.';
          this.hasError = true;
        }
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.number_type &&
        this.data.properties.number_type === "PERCENTAGE"
      ) {
        let fieldValue = parseInt(this.form[this.data.key]);
        if (typeof fieldValue == "number") {
          this.$set(this.form, this.data.key, fieldValue);
        } else {
          this.form[this.data.key] = "";
        }
        let minValue = parseInt(this.data.min_value);
        let maxValue = parseInt(this.data.max_value);
        if (templateConstants.numberPattern.test(this.form[this.data.key])) {
          if (
            (this.data.max_value !== null &&
              this.data.max_value !== undefined) ||
            (this.data.min_value !== null && this.data.min_value !== undefined)
          ) {
            if (this.checkInBetween(fieldValue, maxValue, minValue)) {
              this.errorMsg = "";
              this.hasError = false;
            } else if (
              this.data.max_value !== null &&
              this.data.max_value !== undefined &&
              this.data.min_value !== null &&
              this.data.min_value !== undefined
            ) {
              this.errorMsg =
                "Value should range between " +
                Math.min(this.data.min_value, this.data.max_value) +
                " and " +
                Math.max(this.data.min_value, this.data.max_value);
              if (minValue === maxValue) {
                this.errorMsg = "You can only enter " + this.data.min_value;
              }
              this.hasError = true;
              this.form[this.data.key] = "";
            } else if (fieldValue > maxValue) {
              this.errorMsg = "Value is greater than " + this.data.max_value;
              this.hasError = true;
              this.form[this.data.key] = "";
            } else if (fieldValue < minValue) {
              this.errorMsg = "Value is less than " + this.data.min_value;
              this.hasError = true;
              this.form[this.data.key] = "";
            } else {
              this.errorMsg = "";
              this.hasError = false;
            }
          } else {
            this.errorMsg = "";
            this.hasError = false;
          }
        } else {
          this.errorMsg = "Please enter a valid number";

          this.hasError = true;
        }
      }
    },
    validateEmail() {
      if (templateConstants.emailPattern.test(this.data.default_value)) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Email Format";
        this.hasError = true;
      }
    },
    checkInBetween(fieldValue, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldValue.between(minValue, maxValue);
    },
    deleteOption(sIndex) {
      this.fieldsData.splice(sIndex, 1);
      this.openSettings();
    },
    addOption() {
      let filteredOpts = this.fieldsData.filter(
        (e) => e.group_key == this.dataKey
      );
      this.option.label = "option " + (filteredOpts.length + 1);
      this.option.name = "option " + (filteredOpts.length + 1);
      (this.option.input_type =
        this.data.input_type == "CHECKBOX" ? "CHECKBOX" : "RADIO"),
        (this.option.group_key = this.dataKey);
      this.fieldsData.push(this.option);
      this.data["group_options"] = this.fieldsData.filter(
        (e) => e.key == this.field.key
      );
      this.openSettings();
    },

    checkMaxMinLen() {
      if (this.data.default_value && this.data.properties.maxlength_value) {
        const defaultValue = this.data.default_value.toString();
        const defautlvalue = defaultValue.length;
        const maxlength = this.data.properties.maxlength_value;

        if (defautlvalue > maxlength) {
          this.data.default_value = "";
        }
      }
    },

    checkMaxMin() {
      if (
        this.data.properties.maxlength_value &&
        this.data.properties.minlength_value
      ) {
        let minValue = parseFloat(this.data.properties.minlength_value);
        let maxValue = parseFloat(this.data.properties.maxlength_value);
        if (maxValue > minValue) {
          this.maxMinErrMsg = "";
          this.maxMinErr = false;
        } else {
          this.maxMinErrMsg =
            "Maximum value should be greater than " + minValue;

          this.maxMinErr = true;
        }
      }

      if (this.data.max_value && this.data.min_value) {
        if (
          this.data.input_type === "CURRENCY" ||
          this.data.input_type === "DECIMAL"
        ) {
          let minValue = parseFloat(this.data.min_value);
          let maxValue = parseFloat(this.data.max_value);
          if (maxValue > minValue) {
            this.maxMinErrMsg = "";
            this.maxMinErr = false;
          } else {
            this.maxMinErrMsg =
              "Maximum value should be greater than " + minValue;
            this.maxMinErr = true;
          }
        }
        if (this.data.input_type == "CHECKBOX") {
          let selGroup = this.groups.filter(
            (g) => g.key == this.data.group.key
          );
          let minValue = parseInt(this.data.min_value);
          let maxValue = parseInt(this.data.max_value);
          if (minValue < 0) {
            this.maxMinErrMsg = "Minimum value can not be less than 1";
            this.maxMinErr = true;
          }
          if (maxValue > selGroup.length) {
            this.maxMinErrMsg =
              "Maximum value can not be greater than " + selGroup.length;
            this.maxMinErr = true;
          }
          if (maxValue <= selGroup.length && minValue > 0) {
            this.maxMinErrMsg = "";
            this.maxMinErr = false;
            this.fieldsData.forEach((field) => {
              if (field.group && this.data.group.key == field.group.key) {
                this.$set(field.group, "min_value", minValue);
                this.$set(field.group, "max_value", maxValue);
              }
            });
          }
        }
      }
    },
    resetIconsPopup() {
      this.addiconlist = false;
    },
    generateKeyForField(previousKey, label) {
      if (previousKey) {
        return previousKey;
      }
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

      return parsedLabel;
    },
    openSettings() {
      bus.$emit("fieldData", this.fieldsData);
    },
    async updateEntityFields(data) {
      //   if(data=="addNewData"){
      //     this.$emit("group");
      //     bus.$emit("groupDetals", this.groups);
      //   }else{
      let group = this.groups.find((g) => g.key == data);
      this.$set(this.data, "group", group);
      bus.$emit("fieldUpdate", this.data);
      // if(this.currentGroupIndex > -1){
      let data1 = this.checkBoxGroupArray.find((grp) => grp.key == data);
      bus.$emit("groupFieldUpdateOnChange", data1);
      // }
      //}
    },
    handleTodayDateChange() {
      if (this.data.properties.defaultTodayDate) {
        let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.data.default_value = moment().tz(localTimezone);
      } else {
        this.data.default_value = null;
      }
    },
    handleCurrentTimeChange() {
      if (this.data.properties.defaultTodayTime) {
        let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.data.default_value = moment().tz(localTimezone).format();
      } else {
        this.data.default_value = null;
      }
    },
    handleTodayDateTimeChange(defaultDateTime) {
      if (defaultDateTime) {
        let localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.data.default_value = moment().tz(localTimezone).format();
      } else {
        this.data.default_value = null;
      }
    },
    onUpdate(payload) {
      this.data.default_value = payload;
      this.data.defaultCountry = payload.countryCode;
      if (!payload.isValid) {
        this.error = true;
        this.errorMsg = `Invalid ${this.data.label}`;
      } else {
        setTimeout(async () => {
          // this.form[this.data.key]=payload;
        }, 1000);

        this.error = false;
        this.errorMsg = "";
      }
    },
    getSupportedFields() {
      let notSupportedFieldTypes = [
        "FILE",
        "ENTITY_VARIABLE",
        "PAYMENT_VARIABLE",
        "FORMULA",
        "AGGREGATE_FUNCTION",
        "CONCATENATE",
        "AUTO_INCREMENT_NUMBER",
      ];
      this.supportedFields = [];
      this.supportedFieldsKeyObject = {};
      let allFields = JSON.parse(JSON.stringify(this.allFieldsData));
      allFields.map((e) => {
        if (
          !notSupportedFieldTypes.includes(e.input_type) &&
          !notSupportedFieldTypes.includes(e.inputType) &&
          e.type != "CONTENT"
        ) {
          if (e.data_table_key) {
            e.key = e.data_table_key + "#" + e.key;
            this.supportedFields.push(e);
          } else {
            this.supportedFields.push(e);
          }
          this.supportedFieldsKeyObject[e.key] = e;
        }
      });
      return this.supportedFields;
    },
    handleAiCheck() {
      if (this.data?.use_ai && !this.supportedFields?.length)
        this.getSupportedFields();
      if (this.data.use_ai && !this.data.ai_autofill_fields?.length) {
        this.data.ai_autofill_fields = this.supportedFields.map((e) => e.key);
      }
      if (this.data.use_ai && !this.data.ai_settings.userPrompt?.length) {
        this.data.ai_settings.userPrompt =
          this.data.ai_settings.userPrompt ?? "check";
      }
    },
    handleAutofillClick() {
      if (this.data.ai_autofill_fields.includes("selectAllOptions")) {
        this.data.ai_autofill_fields = this.supportedFields.map((e) => e.key);
      } else if (this.data.ai_autofill_fields.includes("unselectAllOptions")) {
        this.data.ai_autofill_fields = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dialog {
  width: 100%;
}

.dialog-content {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dialog-content::-webkit-scrollbar {
  width: 8px;
}

.dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: #888;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.blue-outline-button {
  border: 2px solid #409eff;
  color: #409eff;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}
.mt-3 {
  margin-top: 10px;
}
.el-select {
  display: block;
}
.field-styling-component {
  margin-right: 12px;
}
.Predit-Value {
  margin-bottom: 12px;
}
.el-radio-button {
  width: 105px !important;
}

.field-styling-drawer {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .scrollable-list {
    max-height: 90vh !important;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  padding: 0em 1.5em;

  .d-flex {
    margin-bottom: 1em;
    margin-top: 1em;

    & > span:first-child {
      min-width: 120px;
    }
  }
  .test {
    display: flex;
    flex-direction: column;
  }
  .el-button:hover {
    background-color: #dbf3fa;
  }

  .el-button:hover .el-icon-date {
    color: rgb(114, 212, 230);
  }
}

.check-box-style {
  margin-top: 10px;
}
</style>
